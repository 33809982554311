const hamburgerBtn = document.querySelector("#hamburgerBtn");
const body = document.querySelector("body");
const header = document.querySelector(".header");
const overlay = document.querySelector(".overlay");
const fadeElems = document.querySelectorAll(".has-fade");

//hamburger button mobile
hamburgerBtn.addEventListener("click", function () {
  if (header.classList.contains("open")) {
    // close hamburger
    body.classList.remove("no-scroll");
    header.classList.remove("open");
    fadeElems.forEach(function (element) {
      element.classList.remove("fade-in");
      element.classList.add("fade-out");
    });
  } else {
    // open hamburger
    body.classList.add("no-scroll");
    header.classList.add("open");
    fadeElems.forEach(function (element) {
      element.classList.remove("fade-out");
      element.classList.add("fade-in");
    });
  }
});

// // change colour of active page in nav
// var btnContainer = document.getElementById("nav-pages");
// var btns = btnContainer.getElementsByClassName("subpage");
// btns[0].classList.add("active");
// for (var i = 0; i < btns.length; i++) {
//   btns[i].addEventListener("click", function () {
//     var current = document.getElementsByClassName("active");
//     current[0].className = current[0].className.replace(" active", "");
//     this.className += " active";
//   });
// }
